/** CUSTOM IMPORTS **/
import { Header } from '../util/header';
import { Histoire } from '../front/histoire'

/** LIBRARY IMPORTS **/
import AOS from 'aos';

export default {

    init() {
        new Header()
        new Histoire()

        initAOS()
    },

    finalize() {

    },

};

function initAOS(){
  AOS.init({
    duration: 900,
    easing: 'ease-out',
  });
  AOS.refresh()
}
