// import local dependencies
import '@css/main.scss';
import jQuery from 'jquery';
import Router from './util/Router';
import common from './routes/common';
import './util/IntersectionObserver';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
});

jQuery(document).ready(() => routes.loadEvents());


const linkActivity = document.querySelector('#menu-item-538');
linkActivity.firstChild.addEventListener('click', function (e) {
  e.preventDefault();
})
