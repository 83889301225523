export class Histoire {

  constructor()
  {
    this.registerSeeMore()
  }

  registerSeeMore(){
    let els = document.querySelectorAll('section.histoire a.more')
    els.forEach((el) => {
      el.addEventListener('click', () => {
          this.handleClick(el)
      })
    })
  }

  handleClick(element){
    let content = element.parentNode.querySelector('.content')
    let initial = content.querySelector('.initial')
    let full = content.querySelector('.full')

    initial.classList.toggle('active')
    full.classList.toggle('active')
    element.classList.toggle('active')
  }

}
